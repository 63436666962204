import React from 'react'
import { Link } from "react-router-dom"
import SeiconeLogo from "../images/logo_text2.png"

function Stats() {
    return (
        <div className="dashboard">
            <div className="dashboard-space">
              <div className="sidebar-div">
                <div className="sidebar">
                  <div className="logo">
                    <img src={SeiconeLogo} alt="" />
                  </div>
                  <h2 >Vítejte v Seicone Admin panelu</h2>
                  <nav>
                    <ul>
                      <Link to="/galerie">Galerie</Link>
                      <Link to="/stats">Statistiky</Link>
                      <Link to="/admins">Admini</Link>
                      <Link to="/content">Obsah</Link>
                      <Link to="/other">Ostatní</Link>
                    </ul>
                    <Link to="/" className="button">Odhlásit se</Link>
                  </nav>
                </div>
              </div>
          </div>
        </div>
      )
}

export default Stats
