import { Link } from "react-router-dom"
import SeiconeLogo from "../images/logo_text2.png"
import 'react-toastify/dist/ReactToastify.css';


const Admin = () => {
  return (
    <div className="dashboard">
        <div className="dashboard-space">
          <div className="sidebar-div">
            <div className="sidebar">
              <div className="logo">
                <img src={SeiconeLogo} alt="" />
              </div>
              <h2 >Vítejte v Seicone Admin panelu</h2>
              <nav>
                <ul>
                  <Link to="/galerie">Galerie</Link>
                  <Link to="/stats">Statistiky</Link>
                  <Link to="/admins">Admini</Link>
                  <Link to="/content">Obsah</Link>
                  <Link to="/other">Ostatní</Link>
                </ul>
                <Link to="/" className="button">Odhlásit se</Link>
              </nav>
            </div>
          </div>
          <div className='dashboard-content'>
                <h1>Admin Dashboard</h1>
                <h3>Loading.....</h3>
          </div>
      </div>
    </div>
  )
}

export default Admin
