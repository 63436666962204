import SeiconeLogo from "../images/logo_text2.png"
import "./AdminLogin.css"
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useState } from "react"

const AdminLogin = () => {
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate();



    const handleSubmit = (e) => {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        e.preventDefault()

        if (name === "Seicone" && password === "SeiconeAdmin@1825") {
            toast.success('Přihlašuji', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            sleep(2000).then(() => {navigate("/Admin")})
        } else {
            toast.error('Neplatné údaje!!', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
  return (
    <>
    <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"

    />
        <section className="admin-login-section">

            <div className="form-div">
                <form onSubmit={handleSubmit}>
                    <img src={SeiconeLogo} alt="" />
                    <input className="form-input username" type="text" placeholder="Přihlašovací jméno" onChange={(e) => setName(e.target.value)}/>
                    <input className="form-input password" type="password" placeholder="Heslo" onChange={(e) => setPassword(e.target.value)}/>
                    <input className="form-input submit" type="submit" value="Přihlísit se"  />
                    <h1>
                        Only admin !
                    </h1>
                </form>
            </div>

        </section>
        <footer>
        <div className="footer-content">
          <div className="footer-col">
            <p>Navigation</p>
            <a href="#second">kontakty</a>
            <a href="/"><Link to="/gallery">Galerie</Link></a>
            <a href="#first">About</a>
          </div>

          <div className="footer-col">
            <p>info</p>
            <p>info@seicone.cz</p>
          </div>

          <div className="footer-col">
            <p>SEICONE s.r.o. <br /> 
            Hajany 56, 664 43 <br />
            firma je zapsána u KS v Brně oddíl C, vložka 121543 <br />
            číslo účtu: 331299/5500 <br />
            IČ: 09903429 <br />
            DIČ: cz09903429</p>
          </div >
        </div>
        <p className="all-rights">© 2024 seicone.cz Všechna práva vyhrazena</p>
      </footer>
    </>
  )
}

export default AdminLogin
