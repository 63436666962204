
const Gallery = () => {
    return (
      <div>
        Gallery
      </div>
    )
  }
  
  export default Gallery
