import logoText from "../images/logo_text2.png"
import { RxHamburgerMenu } from "react-icons/rx";
import thinkingMan from "../images/thinkingMan.png"
import workingMan from "../images/workingMan.png"
// import panelyBackground from "../images/panelyBackground.mp4"
import kolecko from "../images/kolecko.png"
import womanProfile from "../images/womanProfile.png"
import manProfile from "../images/manProfile.png"
import novazelenaImage from "../images/novazelena.jpg"
import { Link } from "react-router-dom";

import { useState } from "react";


const Main = () => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div>
      <section id="first">
        <header>
            <nav>
                <div className="navigation">
                    <div className="nav-header">
                        <img src={logoText} alt="" />
                        <button onClick={() => setShowMenu(!showMenu)}><RxHamburgerMenu className="nav-btn"/></button>
                    </div>
                    <div className={`${showMenu ? "nav-links show" : "nav-links hide" } `}>
                        <ul>
                            <li><a href="#first">O nás</a></li>
                            <li><a href="/"><Link to="/gallery">Foto galerie</Link></a></li>
                            <li><a href="#second">Kontakty</a></li>
                            <button className="nav-btn-login nav-btn-login-mobile"><Link to="/adminLogin" className="login-btn">Login</Link></button>
                        </ul>
                    </div>
                    <div className="nav-login">
                        <button className="nav-btn-login nav-btn-login-normal"><Link to="/adminLogin" className="login-btn">Login</Link></button>
                    </div>
                </div>
            </nav>
        </header>

        <div className="faq-one">
          <div className="faq-one-c-one faq-card">
            <h1>O nás</h1>
            <p>Jsme malá rodinná firma. <br /> Tvoříme a realizujeme projekty v odvětví elektro, převážně na jižní moravě</p>
            <img src={thinkingMan} alt="" />
          </div>
          <div className="faq-one-c-two faq-card">
            <h1>Co děláme?</h1>
            <p>-Fotovoltaické elektrárny <br /> -Domovní rozvody elektro <br /> -Vyřízení dotace <br /> -Kamerové zabezpečení <br /> -Projektová činnost elektro <br /> -Poradenská činnost-IT, elektro
            
            
            </p>
            <img src={workingMan} alt="" />
          </div>
        </div>
      </section>

      <section id="second">
        <div className="kolecka">
          <div className="kolecko-one kolecko">
            <img src={kolecko} alt="" />
            <div className="profile-info">
              <div>
                <img src={womanProfile} alt="" />
              </div>
              <div>
                <h1>Jana Němcová</h1>
                <h2>Sales manager</h2>
                <p>Tel: 777 001 285 <br />
                   E-mail: Jana@seicone.cz
                </p>
              </div>
            </div>
          </div>
          <div className="kolecko-two kolecko">
          <img src={kolecko} alt="" />
            <div className="profile-info-two">
              <div>
                <img src={manProfile} alt="" />
              </div>
              <div>
                <h1>Petr Němec</h1>
                <h2>Project manager</h2>
                    <p>Tel: 777 001 285 <br /> E-mail: Petr@seicone.cz</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="novazelena">
        <img src={novazelenaImage} alt="" />
        <h1><a href="https://novazelenausporam.cz">NOVAZELENAUSPORAM.cz</a></h1>
      </section>

      <footer>
        <div className="footer-content">
          <div className="footer-col">
            <p>Navigation</p>
            <a href="#second">kontakty</a>
            <a href="/"><Link to="/gallery">Galerie</Link></a>
            <a href="#first">About</a>
          </div>

          <div className="footer-col">
            <p>info</p>
            <p>info@seicone.cz</p>
          </div>

          <div className="footer-col">
            <p>SEICONE s.r.o. <br /> 
            Hajany 56, 664 43 <br />
            firma je zapsána u KS v Brně oddíl C, vložka 121543 <br />
            číslo účtu: 331299/5500 <br />
            IČ: 09903429 <br />
            DIČ: cz09903429</p>
          </div >
        </div>
        <p className="all-rights">© 2024 seicone.cz Všechna práva vyhrazena</p>
      </footer>
    </div>
  )
}

export default Main;
