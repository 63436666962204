import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Gallery from './pages/Gallery'
import AdminLogin from './pages/AdminLogin'
import Admin from './pages/Admin'
import Main from './pages/Main'
import Galerie from './components/Galerie'
import Stats from './components/Stats'
import Admins from './components/Admins'
import Content from './components/content'
import Other from './components/Other'


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />}></Route>
        <Route path='/adminLogin' element={<AdminLogin />}></Route>
        <Route path='/admin' element={<Admin />}></Route>
        <Route path='/gallery' element={<Gallery />}></Route>
        <Route path='/galerie' element={<Galerie />}></Route>
        <Route path='/stats' element={<Stats />}></Route> 
        <Route path='/admins' element={<Admins />}></Route> 
        <Route path='/content' element={<Content />}></Route>
        <Route path='/other' element={<Other />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App